(function($) {

  $.fn.menumaker = function(options) {

      var cssmenu = $(this), settings = $.extend({
        title: "Menu",
        format: "dropdown",
        sticky: false
    }, options);

      return this.each(function() {
        cssmenu.prepend('<div id="menu-button">' + settings.title + '</div>');
        $(this).find("#menu-button").on('click', function(){
          $(this).toggleClass('menu-opened');
          var mainmenu = $(this).next('ul');
          if (mainmenu.hasClass('open')) { 
            mainmenu.hide().removeClass('open');
        }
        else {
            mainmenu.show().addClass('open');
            if (settings.format === "dropdown") {
              mainmenu.find('ul').show();
          }
      }
  });

        cssmenu.find('li ul').parent().addClass('has-sub');

        multiTg = function() {
          cssmenu.find(".has-sub").prepend('<span class="submenu-button"></span>');
          cssmenu.find('.submenu-button').on('click', function() {
            $(this).toggleClass('submenu-opened');
            if ($(this).siblings('ul').hasClass('open')) {
              $(this).siblings('ul').removeClass('open').hide();
          }
          else {
              $(this).siblings('ul').addClass('open').show();
          }
      });
      };

      if (settings.format === 'multitoggle') multiTg();
      else cssmenu.addClass('dropdown');

      if (settings.sticky === true) cssmenu.css('position', 'fixed');

      resizeFix = function() {
          if ($( window ).width() > 770) {
            cssmenu.find('ul').show();
        }

        if ($(window).width() <= 770) {
            cssmenu.find('ul').hide().removeClass('open');
        }
    };
    resizeFix();
    return $(window).on('resize', resizeFix);

});
  };
})(jQuery);

(function($){
    $(document).ready(function(){

        $("#cssmenu").menumaker({
         title: "Menu",
         format: "multitoggle"
     });

    });
})(jQuery);

jQuery(document).ready(function(){
    jQuery('.card-header').click(function(){
        jQuery('.card-collapse').slideUp(700).addClass('collapse');
        jQuery('.indicator').removeClass('mbri-arrow-down').addClass('mbri-arrow-prev');
        jQuery(this).closest('.card').find('.card-collapse').slideDown(700).removeClass('collapse');
        jQuery(this).closest('.card').find('.indicator').removeClass('mbri-arrow-prev').addClass('mbri-arrow-down');
    });
});


jQuery(document).ready(function(){
    var maxLength = 1;
    jQuery(".show-read-more").each(function(){
        var myStr = jQuery(this).html();
        if($.trim(myStr).length > maxLength){
            var newStr = myStr.substring(0, maxLength);
            var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
            jQuery(this).empty().html(newStr);
            jQuery(this).append(' <a href="javascript:void(0);" class="read-more">read more...</a>');
            jQuery(this).append('<span class="more-text">' + removedStr + '</span>');
        }

    });

    jQuery(".read-more").click(function(){
        jQuery(this).siblings(".more-text").contents().unwrap();
        jQuery(this).remove();
    });



     jQuery('.has-sub a').each(function(){
        jQuery(this).click(function(){
          jQuery(this).closest('li').find('ul:first').slideToggle();
          if( jQuery(window).width() <= 768 && jQuery(this).closest('li').hasClass('has-sub')) {
            jQuery(this).attr('href', 'javascript: void(null)');
          }
        });
      });



});

 /*function toggleChevron(e) {
    jQuery(e.target)
        .prev('.panel-heading')
        .find("span.indicator")
        .toggleClass('mbri-arrow-down mbri-arrow-prev');
        return false;
          $('html,body').animate({
            scrollTop: jQuery(e.target).find("span.indicator").offset().top
          }, 500); 
}
jQuery(document).ready(function(){
jQuery('#accordion').click(function(e){
  e.preventDefault();
});

jQuery('#accordion').on('hidden.bs.collapse', toggleChevron);
jQuery('#accordion').on('shown.bs.collapse', toggleChevron);
}); */



